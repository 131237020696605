const BE_URL_DEV = 'https://staging-api.datalemur.com';
const NEXT_BE_URL_DEV = 'http://localhost:3000/api';
const SITE_URL_DEV = 'https://staging.datalemur.com';

export const AUTH0_RESET_PASSWORD_URL = 'https://auth.datalemur.com/dbconnections/change_password';

export const PYODIDE_INDEX_URL = 'https://cdn.jsdelivr.net/pyodide/v0.25.1/full';

export const BE_URL = process.env.NEXT_PUBLIC_BE_URL || BE_URL_DEV;
export const NEXT_BE_URL = process.env.NEXT_PUBLIC_NEXT_BE_URL || NEXT_BE_URL_DEV;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || SITE_URL_DEV;

export const BE_JWT_TOKEN = process.env.BE_JWT_TOKEN;

export const STRIPE_PK = process.env.NEXT_PUBLIC_STRIPE_PK || '';
export const STRIPE_SK = process.env.STRIPE_SK || '';
export const STRIPE_WEBHOOK_SUBSCRIPTION_SECRET = process.env.STRIPE_WEBHOOK_SUBSCRIPTION_SECRET || '';

export const REFRESH_TOKEN_COOKIE_NAME = process.env.REFRESH_TOKEN_COOKIE_NAME || 'refresh_token';

export const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME || 'Datalemur';

export const IS_SERVER = typeof window === 'undefined';

export const IS_PRODUCTION = process.env.VERCEL_ENV === 'production';
export const IS_PRODUCTION_ACCESSIBLE_FE = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const HJID = process.env.NEXT_PUBLIC_HJID ? Number(process.env.NEXT_PUBLIC_HJID) : null;
export const HJSV = process.env.NEXT_PUBLIC_HJSV ? Number(process.env.NEXT_PUBLIC_HJSV) : null;
export const MP_ID = '53bd5177241051a506296617f90df1bc';

export const PROGRESS_BAR_CONFIG = {
  color: '#FC8181',
  height: 4,
  startPosition: 0.3,
  stopDelayMs: 0,
  showOnShallow: true,
  options: { easing: 'ease', speed: 500, showSpinner: false },
};

export const MAX_UPLOAD_IMG_SIZE_IN_BYTES = 3145728;

export const AUTH0_RESET_PASSWORD_CONNECTION = 'Username-Password-Authentication';

export const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong =/, please try later.';

export const RESEND_VERIFY_EMAIL_SUCCEEDED_MESSAGE = 'Verification email is successfully sent.';

export const REFERRAL_CODE_KEY = 'referralCode';

export const BONUS_QUESTIONS_ANCHOR = 'bonus-questions';

export const REVALIDATION_SECRET = process.env.REVALIDATION_SECRET || '';

export const YOUTUBE_URL = 'https://www.youtube.';

export const YOUTUBE_SHORT_URL = 'https://youtu.be';

export const ACTIVE_CAMPAIGN_API_URL = process.env.ACTIVE_CAMPAIGN_API_URL;
export const ACTIVE_CAMPAIGN_API_KEY = process.env.ACTIVE_CAMPAIGN_API_KEY;

export const POSTMARK_API_KEY = process.env.POSTMARK_API_KEY;

export const NICKS_EMAIL = 'nick@datalemur.com';

export const DEFAULT_SHARING_TEXT = 'DataLemur - Ace the SQL & Data Science Interview';
