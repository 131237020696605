import { extendTheme } from '@chakra-ui/react';
import { tabsTheme } from '@/theme/components';
import { breakpoints, dropdownMenuStyles } from '.';

const KUMBH_SANS_FONT = 'Kumbh Sans';

export const chakraTheme = extendTheme({
  breakpoints,
  fonts: {
    KumbhSans: `"${KUMBH_SANS_FONT}", sans-serif`,
    body: `ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  },
  colors: {
    red: {
      100: '#F8CBC5',
      200: '#DC3B26',
      300: '#A12B1B',
      400: '#CB171E',
      500: '#ED1C24',
      600: '#ED1C24',
      700: '#ED1C24',
      800: '#ED1C24',
      900: '#74170B',
    },
    green: {
      100: '#BBEBCD',
      200: '#16A34A',
      300: '#17723A',
      400: '#104B27',
    },
    pink: {
      200: '#F9CFD1',
    },
    orange: {
      100: '#FAE0B4',
      200: '#D98506',
      300: '#915103',
      400: '#663800',
    },
    gray: {
      100: '#FCFCFC', // background and header
      200: '#F0F0F0',
      300: '#E5E5E5',
      400: '#DADADA',
      500: '#CCCCCC',
    },
    black: {
      200: '#4D4D4D', // nav items
      300: '#666666',
      500: '#0A0A0A', // global font color
    },
    blue: {
      100: '#DAEAF3',
      200: '#3182CE',
    },
    linkedInBlue: '#0A66C2',
    facebookBlue: '#1778F2',
    tableOddRows: {
      100: '#F5F5F5',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: KUMBH_SANS_FONT,
        fontWeight: 700,
      },
      variants: {
        wrapper: {
          minHeight: 'unset',
          height: 'unset',
          padding: '6px 12px',
          _hover: { bg: 'gray.200' },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: KUMBH_SANS_FONT,
      },
      variants: {
        main: {
          fontSize: '32px',
          lineHeight: 1.5,
        },
        secondary: {
          fontSize: '24px',
          lineHeight: 1.5,
        },
      },
    },
    Table: {
      variants: {
        striped: {
          tbody: {
            tr: {
              '&:nth-of-type(odd)': {
                backgroundColor: 'tableOddRows', // for some reason gray.value doesn't set the right color
              },
            },
          },
        },
      },
    },
    Menu: dropdownMenuStyles,
    Tabs: tabsTheme,
  },
});

export default chakraTheme;
